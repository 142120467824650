@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
	font-family: 'REZ';
	src: url('https://frontmax.dev/font/Rezland%20Logotype%20Font%20400.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }

*{
	box-sizing: border-box;
}

/* Ширина полосы прокрутки */
body::-webkit-scrollbar {
	width: 10px;
}

/* Цвет фона полосы прокрутки */
body::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Цвет полосы прокрутки */
body::-webkit-scrollbar-thumb {
	background: #888888;
	border-radius: 3px;
}

/* При наведении на полосу прокрутки */
body::-webkit-scrollbar-thumb:hover {
	background: #555;
}

html, body{
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100%;
	/*overflow-x: hidden;*/
	color: #fff;
	font-family: 'Inter', sans-serif;
	background: linear-gradient(180deg, #0D1116 0%, #131727 99.99%, rgba(19, 23, 39, 0) 100%);
}

#root{
    position: relative;
    width: 100%;
    height: 100%;
	max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

p, h1, h2{
	margin: 0;
	padding: 0;
}

.headlineMainPhrase, 
.phoneInfoUser div:nth-child(1),
.GitHub_body_code > div,
.copyright,
.footerQuote blockquote p,
.footerQuote figcaption{
	font-size: 16px;
}

.myButtonTriangleFull,
.myButtonTriangleEmpty{
	font-size: 20px;
}

.introduceHeader p,
.colorSectionTextBlock p, 
.skillsText, 
.experienceText > div div:nth-child(2), 
.footerContacts > a,
h2{
	font-size: 24px;
}

h1{
	font-size: 32px;
}

.socialNetworksBlock a, .icon{
	font-size: 40px;
}

.neonHeadlin,
.colorSectionTextBlock h2{
	font-size: 64px;
}

section{
	padding: 40px 75px;
}

header{
	padding: 24px 75px;
}

.footerContacts,
.footerBottom{
	padding: 0 75px;
}

.introduceHeader p {
    padding: 20px 0;
}

.experienceText,
#SampleProjects, 
.SampleProjects_line1, 
.SampleProjects_line2{
	grid-gap: 40px;
}

.colorSectionTextBlock div,
.skillsText{
	grid-gap: 20px;
}

@media (max-width: 600px){

	.introduceHeader p,
	.colorSectionTextBlock p, 
	.skillsText, 
	.experienceText > div div:nth-child(2), 
	.footerContacts > a,
	.myButtonTriangleFull,
	.myButtonTriangleEmpty{
		font-size: 16px;
	}
	.neonHeadlin,
	.colorSectionTextBlock h2{
		font-size: 36px;
	}


	.footerContacts, .footerBottom, header{
		padding: 20px;
	}

	section{
		padding: 40px 20px;
	}

	.experienceText#SampleProjects, 
	.SampleProjects_line1, 
	.SampleProjects_line2,
	.colorSectionTextBlock div,
	.footerContacts,
	.footerBottom{
		grid-gap: 20px;
	}

}

h1{
	font-weight: 500;
}

a{
	text-decoration: none;
}

header{
    display: flex;
    justify-content: space-between;
	align-items: center;
	z-index: 99999999;
	position: absolute;
    width: 100%;
}

.menu{
	position:fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(180deg, #0D1116 0%, #131727 100%);
	z-index: -1;
	padding-top: 78px;
	display: flex;
	flex-direction: column;
    align-items: center;
	justify-content: center;
	grid-gap: 40px;
	opacity: 0;
  	animation: openMenu 1s forwards;
}

@keyframes openMenu {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

.menu nav{
	display: flex;
    justify-content: center;
	flex-wrap: wrap;
	grid-gap: 20px;
	width: 326px;
}

.menu nav a {
	display: flex;
    justify-content: center;
    flex-direction: column;
    width: 153px;
    height: 153px;
    text-align: center;
	border: 2px solid #FFFFFF;
	border-radius: 20px;
	color: #fff;
}

.menu nav a:hover{
	opacity: 0.7;
}

.menu nav svg{
	font-size: 36px;
	color: #fff;
}

.menu nav div{
	font-size: 20px;
	font-weight: 300;
	margin-top: 5px;
}

.menuLinksActive{
	background: #fff;
}

.menuLinksActive svg, .menuLinksActive div {
	color: #131727 !important;
}

.socialNetworksBlock{
	grid-gap: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.socialNetworksBlock a{
	color: #fff;
}

.socialNetworksBlock a:hover{
	transform: scale(1.1);
}

.icon{
	color: #fff;
	cursor: pointer;
}

.menu::after, .introduceProjectBackground::after{
	content: '';
	position: absolute;
	width: 415px;
	height: 350px;
	left: -19%;
    top: -32%;
	background: rgba(242, 85, 76, 0.89);
	filter: blur(152px);
	animation: anim1 25s linear infinite;
	z-index: -1;
}

.menu::before, .introduceProjectBackground::before{
	content: '';
	position: absolute;
	width: 369px;
	height: 208px;
	right: -19%;
	bottom: -32%;
	background: #61EFFF;
	filter: blur(152px);
	animation: anim2 25s linear infinite;
	z-index: -1;
}

.introduceProjectBackground::after, .introduceProjectBackground::before{
	z-index: 2;
    width: 200px;
    height: 200px;
    filter: blur(100px);
}

@keyframes anim1 {
    0% {left: -17%; top: -30%;}
    20% {left: -13%; top: 20%; transform: rotate(45deg);}
    30% {left: -5%; top: 25%;}
    40% {left: 10%; top: 5%;}
    50% {left: 25%; top: 10%;}
    60% {left: 10%; top: 100%; transform: rotate(-180deg);}
    70% {left: 20%; top: 70%;}
    80% {left: -20%; top: 30%;}
    90% {left: 10%; top: 10%;}
    100% {left: -17%; top: -30%;}
}

@keyframes anim2 {
    0% {right: -17%; bottom: -30%;}
    20% {right: -13%; bottom: 20%; transform: rotate(-45deg);}
    30% {right: -5%; bottom: 25%;}
    40% {right: 10%; bottom: 5%;}
    50% {right: 25%; bottom: 10%;}
    60% {right: 10%; bottom: 100%; transform: rotate(180deg);}
    70% {right: 20%; bottom: 70%;}
    80% {right: -20%; bottom: 30%;}
    90% {right: 10%; bottom: 10%;}
    100% {right: -17%; bottom: -30%;}
}

.backgroundMain{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	background-image: url(img/backgroundMain.jpg);
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.socialNetworksMain{
	bottom: 24px;
    position: absolute;
    left: 75px;
}

.headlineMain{
	position: absolute;
    width: auto;
	height: auto;
	top: 50%;
	left: 50%;
	margin-right: -50%;
    transform: translate(-50%, -50%);
	z-index: 3;
}

.headlineMain h1{
	color: #353F2A;
    font-size: 96px;
    font-weight: 900;
    text-align: center;
}

.headlineMain h1 mark{
	background-image: url(img/flagUA.png);
	-webkit-background-clip:text;
  	-webkit-text-fill-color: transparent;
	background-position: center;
	background-size: contain;
}

.headlineMainPhrase{
	position: absolute;
	left: 0;
	font-style: normal;
	font-weight: 500;
	text-transform: uppercase;
	color: #FFFFFF;
	top: -15px;
}

.headlineMainDescription{
	width: 44%;
    margin-left: auto;
    position: absolute;
	right: 0;
	text-align: right;
}

.headlineMainDescription a{
    color: #fff;
	text-decoration: revert;
	margin-top: 20px;
    position: relative;
    display: block;
}

@media all and (max-width: 890px) {
	.headlineMain h1{
		font-size: 70px;
	}
	.headlineMainDescription{
		width: 60%;
	}
}

@media all and (max-width: 660px) {
	.headlineMain h1{
		font-size: 41px;
	}
	.headlineMainDescription{
		width: 91%;
		font-size: 15px;
	}
	.headlineMainPhrase{
		font-size: 13px;
	}
}

.cloudDefault, .ufoDefault{
	position: absolute;
}

.cloud_1{
	width: 44%;
    height: auto;
	left: 10%;
	top: 4%;
	animation: cloud 5s linear forwards;
	animation-delay: 0.5s;
	opacity: 0;
	z-index: 2;
}

.cloud_2{
	width: 46%;
    height: auto;
	left: 33%;
	top: 4%;
	animation: cloud 5s linear forwards;	
	opacity: 0;
	z-index: 1;
}

.cloud_3{
	width: 44%;
    height: auto;	
	left: 50%;
	top: 10%;	
	animation: cloud 5s linear forwards;
	animation-delay: 1.3s;
	opacity: 0;
	z-index: 2;
}

.cloud_4{
	width: 40%;
    height: auto;
	left: 54%;
    top: 20%;	
	animation: cloud 5s linear forwards;
	animation-delay: 0.8s;
	opacity: 0;
	z-index: 2;
}

@keyframes cloud {
    0% {opacity: 0;transform: scale(0);}
    10% {opacity: 0.1;}
    20% {opacity: 0.2;}
    30% {opacity: 0.3;}
    40% {}
    50% {transform: scale(1);}
    60% {opacity: 0.4;}
    70% {opacity: 0.7;}
    80% {opacity: 0.8;}
    90% {opacity: 0.9; }
    100% {opacity: 1;}
}

.ufoDefault img{
	width: 100%;
}

.ufo_1{
	width: 20%;
	height: auto;
	transform: rotate(-14deg);
	left: 5%;
    top: 17%;
	z-index: 1;
	opacity: 0;
	animation: ufo1 5s linear forwards;
	animation-delay: 6s;
}

.ufo_2{
	width: 23%;
	height: auto;
	left: 35%;
    top: 19%;
	z-index: 1;
	opacity: 0;
	animation: ufo2 5s linear forwards;
	animation-delay: 6s;
}

.ufo_3{
	width: 13%;
	height: auto;
	transform: rotate(-9.5deg);
	left: 74%;
    top: 21%;
	z-index: 1;
	opacity: 0;
	animation: ufo3 5s linear forwards;
	animation-delay: 6s;
}

.ufo_1::after{
	content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0;
    border-color: transparent transparent #ffe85269 transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: -1;
    top: 28px;
    border-radius: 17px;
	animation: ray1 35s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
	animation-delay: 16s;
}

.ufo_2::after{
	content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0;
    border-color: transparent transparent #ffe85269 transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: -1;
    top: 28px;
    border-radius: 17px;
	animation: ray2 25s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
	animation-delay: 9s;
}

.ufo_3::after{
	content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0;
    border-color: transparent transparent #ffe85269 transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: -1;
    top: 28px;
    border-radius: 17px;
	animation: ray3 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
	animation-delay: 20s;
}

@keyframes ufo1 {
    0% {left: 50%; width: 20px; opacity: 1; z-index: -1;}
    10% {}
    20% {}
    30% {}
    40% {}
    50% {}
    60% {}
    70% {}
    80% {}
    90% {}
    100% {opacity: 1; z-index: 1;}
}

@keyframes ufo2 {
    0% {left: 50%; width: 20px; opacity: 1; z-index: -1;}
    10% {}
    20% {}
    30% {}
    40% {}
    50% {}
    60% {}
    70% {}
    80% {}
    90% {}
    100% {opacity: 1; z-index: 1;}
}

@keyframes ufo3 {
    0% {left: 50%; width: 20px; opacity: 1; z-index: -1;}
    10% {}
    20% {}
    30% {}
    40% {}
    50% {}
    60% {}
    70% {}
    80% {}
    90% {}
    100% {opacity: 1; z-index: 1;}
}

@keyframes ray1 {
    0% {border-width: 0;}
    10% {}
    20% {border-width: 0 59px 410px 59px;opacity: 1;}
    30% {opacity: 0.3;}
    40% {opacity: 0.2;}
    50% {border-width: 0 59px 410px 59px;opacity: 1;}
    60% {border-width: 0;}
    70% {}
    80% {}
    90% {}
    100% {border-width: 0;}
}

@keyframes ray2 {
    0% {border-width: 0;}
    10% {}
    20% {border-width: 0 59px 350px 59px;opacity: 1;}
    30% {opacity: 0.3;}
    40% {opacity: 0.2;}
    50% {border-width: 0 59px 350px 59px;opacity: 1;}
    60% {border-width: 0;}
    70% {}
    80% {}
    90% {}
    100% {border-width: 0;}
}

@keyframes ray3 {
    0% {border-width: 0;}
    10% {}
    20% {border-width: 0 59px 340px 59px;opacity: 1;}
    30% {opacity: 0.3;}
    40% {opacity: 0.2;}
    50% {border-width: 0 59px 340px 59px;opacity: 1;}
    60% {border-width: 0;}
    70% {}
    80% {}
    90% {}
    100% {border-width: 0;}
}

.introduceBlock{
	width: 100%;
	height: 110vh;
	position: relative;
	background: none !important;
	overflow-x: hidden;
	max-height: 800px;
}

.introduceBlock video{
	position: absolute;
	bottom: 0;
	min-width: 110%;
	min-height: 100%;
	left: 50%;
   	transform: translate(-50%, 0);
	z-index:0;
}

.blockGraphic_1{
	position: absolute;
    left: 0;
    top: -45px;
    width: 100%;
    height: 75px;
}

.blockGraphic_2{
	position: absolute;
    left: 0;
    bottom: -55px;
    width: 100%;
    height: 75px;
	z-index: -1;
}

.introduceHeaderBclock{
	width: 100%;
	max-width: 630px;
	position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.introduceHeader h1{
	font-style: normal;
	font-weight: 800;
	font-size: 70px;
	color: #F2554C;
}

.introduceHeader p{
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
	color: #FFFFFF;
}

.myButtonTriangleFull{
	width: 170px;
	height: 65px;
	color: #fff;
	background: #F2554C;
	border: 1px solid #F2554C;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.myButtonTriangleFull::after{
	content: '';
    width: 100%;
    height: 100%;
    background: #F2554C;
    border: 1px solid #F2554C;
    border-radius: 15px;
    transform: skew(30deg);
    left: 30px;
    position: absolute;
    z-index: -1;
}

.myButtonTriangleEmpty{
	width: 170px;
	height: 65px;
	color: #fff;
	background: none;
	border: 1px solid #ffffff;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border-left: none;
	border-radius: 0 15px 15px 0;
}

.myButtonTriangleEmpty::after{
	content: '';
    width: 90%;
    height: 100%;
    background: none;
    border: 1px solid #ffffff;
    border-radius: 15px;
    transform: skew(30deg);
    left: -30px;
    position: absolute;
    z-index: -1;
	border-right: none;
	border-radius: 15px 0  0 15px;
}

.introduceButton{
	display: flex;
	grid-gap: 80px;
}

.myButtonTriangleEmpty:hover, .myButtonTriangleFull:hover{
	opacity: 0.8;
}

@media all and (max-width: 800px) {
	.introduceHeaderBclock {
    	max-width: calc(100% - 40px);
	}
	.myButtonTriangleFull{
		width: 125px;
		height: 60px;
	}
	.myButtonTriangleEmpty{
		width: 125px;
		height: 60px;
	}
	.myButtonTriangleFull::after{
		width: 125px;
		height: 58px;
	}
	.myButtonTriangleEmpty::after{
		width: 125px;
		height: 58px;
	}
	.introduceHeaderBclock{
		top: auto;
		bottom: 17%;
		transform: translate(0, 17%);
	}
}

.phoneBlock{
	position: absolute;
    top: 50%;
    right: 22%;
    transform: translate(22%, -50%);
    perspective: 800px;
}

@media all and (max-width: 870px) {
	.phoneBlock{
		display: none;
	}
}

.phoneFrame{
	width: 235px;
    height: 470px;
    background: #000000;
    border-radius: 33px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform-style: preserve-3d;
	transform: rotateY(325deg) translateZ(-2000px) rotateX(3deg);
	transition: all 1.0s ease-in-out;
	animation: Phone 10s linear forwards;
}

@keyframes Phone{
	0%{
		transform: rotateY(325deg) translateZ(-2000px) rotateX(3deg);
	}
	20%{
		transform: rotateY(325deg) translateZ(-100px) rotateX(3deg);
	}
	80%{
		transform: translateZ(-20px);
	}
	100%{
		transform: rotateY(360deg) translateZ(-2px) rotateX(360deg);
		transform-style: flat;
	}
}

.phoneScreen{
	position: relative;
	width: calc(100% - 18px);
	height: calc(100% - 18px);
	background: rgba(255, 255, 255, 0.2);
	border-radius: 23px;
	overflow: hidden;
}

.phoneWallpaper{
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url(img/myPhoto.jpg);
	background-size: cover;
    background-position: center;
	filter: blur(4px);
}

.phoneBlock::after{
	content: '';
    position: absolute;
    width: 175px;
    height: 175px;
    right: -50px;
    top: -50px;
    background: #FFFFFF;
    filter: blur(100px);
    z-index: -1;
    border-radius: 50%;
	opacity: 0;
	animation: backlightPhone 1s forwards;
	animation-delay: 9s;
}

.phoneBlock::before{
	content: '';
    position: absolute;
    width: 175px;
    height: 175px;
    left: -50px;
    bottom: -50px;
    background: #FFFFFF;
    filter: blur(100px);
    z-index: -1;
    border-radius: 50%;
	opacity: 0;
	animation: backlightPhone 1s forwards;
	animation-delay: 9s;
}

@keyframes backlightPhone {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

.phoneHeader{
	width: 100%;
    height: auto;
    position: absolute;
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
	align-items: center;
}

.phoneHeader time{
	font-size: 10px;
}

.phoneIcons{
	grid-gap: 5px;
    display: flex;
}

.phoneCamera{
	width: 46px;
	height: 16px;
	background: #000000;
	border-radius: 27px;
	position: absolute;
	left: 50%;
    transform: translate(-50%, 0);
	display: flex;
	justify-content: right;
	align-items: center;
	padding: 0 8px;
}

.phoneCameraHole{
	position: absolute;
	width: 8px;
	height: 8px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phoneCameraFocus{
	width: 6px;
	height: 6px;
	background: #000000;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.phoneCameraFocus div{
	width: 4px;
	height: 4px;
	background: #20316C;
	filter: blur(2px);
	border-radius: 50%;
}

.phoneInfoBlock{
	position: absolute;
	width: 100%;
	height: auto;
	top: 50px;
	padding: 0 24px;
	grid-gap: 10px;
    display: flex;
}

.phonePhotoUser{
	height: 34px;
	width: 34px;
	border-radius: 50%;
	background-image: url(img/myPhoto2.jpg);
	background-position: center;
	background-size: cover;
}

.phoneButtons{
	position: absolute;
	width: 100%;
	padding: 0 24px 50px 24px;
	bottom: 0;
	flex-wrap: wrap;
    grid-gap: 30px;
    display: flex;
}

.phoneButtons > div {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.phoneButtonName{
	font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
    padding-top: 5px;
}
.phoneButton{
	width: 55px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.phoneButtonFull{
	width: 42px;
	height: 42px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phoneButtonDecline{
	background: #FF453A;
}

.phoneButtonAccept{
	background: #30D158;
	box-shadow: 0 8px 10px #30D158;
    cursor: pointer;
	transition: .3s;
    -webkit-animation: hoverWave linear 1s infinite;
    animation: hoverWave linear 1s infinite;
}

@-webkit-keyframes hoverWave {
	0% {
	box-shadow:0 8px 10px rgba(64,187,84,0.2),0 0 0 0 rgba(64,187,84,0.2),0 0 0 0 rgba(64,187,84,0.2)
}
40% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 15px rgba(64,187,84,0.2),0 0 0 0 rgba(64,187,84,0.2)
}
80% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 30px rgba(64,187,84,0),0 0 0 26.7px rgba(64,187,84,0.067)
}
100% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 30px rgba(64,187,84,0.0),0 0 0 40px rgba(64,187,84,0.0)
}
}@keyframes hoverWave {
	0% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 0 rgba(64,187,84,0.2),0 0 0 0 rgba(64,187,84,0.2)
}
40% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 15px rgba(64,187,84,0.2),0 0 0 0 rgba(64,187,84,0.2)
}
80% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 30px rgba(64,187,84,0),0 0 0 26.7px rgba(64,187,84,0.067)
}
100% {
	box-shadow:0 8px 10px rgba(64,187,84,0.3),0 0 0 30px rgba(64,187,84,0),0 0 0 40px rgba(64,187,84,0.0)
}
}

.promptPhon{
	position: absolute;
    width: 168px;
    height: 37px;
    right: -67%;
    top: 320px;
    transform: skew(137deg);
    border-left: 4px solid rgb(255 255 255 / 65%);
    border-top: 2px solid rgb(255 255 255 / 65%);
}
.promptPhon:before {
	content: 'Accept a phone call';
	position: absolute;
	top: -22px;
	right: 0;
	bottom: 0;
	color: #fff;
	transform: skew(-137deg);
	font-size: 15px;
}

.phoneInfoUser div:nth-child(1){
	font-style: normal;
	font-weight: 500;
	color: #FFFFFF;
}

.phoneInfoUser div:nth-child(2){
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	color: rgba(255, 255, 255, 0.5);
}

@media all and (max-width: 600px) {
	.menu::after{
		width: 150px;
		height: 100px;
		filter: blur(80px);
	}
	
	.menu::before{
		width: 150px;
		height: 100px;
		filter: blur(80px);
	}

	.socialNetworksMain{
		display: none;
	}

	.cloud_1 {
		left: 4%;
		top: 18%;
		z-index: 1;
	}

	.cloud_2 {
		left: 23%;
		top: 16%;
	}

	.cloud_3 {
		left: 40%;
    	top: 16%;
	}

	.cloud_4 {
		left: 50%;
		top: 21%;
	}

	.ufo_2 {
		width: 36%;
		height: auto;
		left: 29%;
		top: 22%;
	}

	.ufo_1 {
		left: 5%;
    	top: 20%;
	}

	.introduceHeader h1 {
    	font-size: 40px;
	}

	.phoneBlock{
		position: absolute;
		top: 32%;
		right: 50%;
		transform: translate(50%, -50%);
		perspective: 800px;
		display: block;
	}

	.phoneFrame{
		width: 213px;
		height: 250px;
	}

	.phoneCamera {
		width: 16px;
		height: 16px;
		justify-content: center;
	}

	.phoneButtons > div:nth-child(1){
		display: none;
	}

	.promptPhon{
		width: 100%;
		top: -5px;
		border: none;
		animation: ticker 5s infinite linear;
		opacity: 0;
		animation-delay: 5s;
		transform: none;
	}

	.promptPhon::before{
		transform: none;
	}

	.phoneBlock::after, .phoneBlock::before{
		width: 100px;
		height: 100px;
		filter: blur(87px);
	}
}

@keyframes ticker {
	0%{
		opacity: 1;
	  	transform: translate(0, 0);
	}
	
	100%{
		opacity: 1;
	  	transform: translate(-210%, 0);
	}
}

section {
	width: 100%;
    height: auto;
    position: relative;
}

.colorSection{
	background: #B1D7D2;
	display: flex;
    justify-content: space-between;
    align-items: center;
	flex-wrap: wrap;
	grid-gap: 40px;
	z-index: 3;
	margin: 20px 0;
}

.colorSectionTextBlock{
	width: 45%;
	height: auto;
}

.colorSectionTextBlock h2{
	font-style: normal;
	font-weight: 800;
	color: #E7472E;
	margin-bottom: 40px;
    margin-top: 0px;
}

.colorSectionTextBlock div{
	display: flex;
    justify-content: center;
	flex-wrap: wrap;
}

.colorSectionTextBlock p{
	font-style: normal;
	font-weight: 400;
	color: #432E33;
}

.instagramLink{
	-webkit-background-clip: text;
	color: transparent;
	text-decoration: underline;
	background-image: linear-gradient(to left,#F6C042 0%, #EE6B2D 21.88%, #EB3324 47.92%, #DB3056 72.4%, #CC2E9D 100%);
	position: relative;
}

.instagramLink span{
	height: 0.125rem;
    bottom: 0.125rem;
    position: absolute;
    left: 0px;
    right: 0px;
    background-image: linear-gradient(to left,#F6C042 0%, #EE6B2D 21.88%, #EB3324 47.92%, #DB3056 72.4%, #CC2E9D 100%);
}

.containerMiniGallery{
	width: 50%;
	height: auto;
	position: relative;
}

.postHeader{
	height: 58px;
	width: 100%;
	position: relative;
	padding: 15px;
	display: flex;
	justify-content: space-between;
    align-items: center;
}

.postHeader img{
	border-radius: 50%;
    height: 100%;
}

.postHeaderText, .postHeaderTextFacebook{
	width: 70%;
	display: flex;
    flex-wrap: wrap;
}

.postHeaderText div{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	width: 100%;
}

.postHeaderText address{
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
}

.postHeaderText time{
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	color: #69696E;
}
.postHeaderTextFacebook div{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #000000;
}

.postHeaderTextFacebook time{
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	color: #69696E;
}

.postImg{
	width: 100%;
	height: 300px;
	overflow: hidden;
}

.postImgFacebook{
	width: 100%;
	height: 245px;
	overflow: hidden;
}

.postImg img, .postImgFacebook img{
	width: 100%;
	height: auto;
}

.postFooter{
	padding: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
	grid-gap: 10px;
}

.line{
	width: 100%;
	height: 1px;
	background: #CBCBCB;
}

.postFooter > div{
	width: 100%;
}

.postFooterButton{
	display: flex;
    justify-content: space-between;
    align-items: center;
	font-size: 12px;
}
.postFooterButton div{
	display: flex;
	grid-gap: 15px;
}

.postFooterLike{
	display: flex;
    justify-content: space-between;
    align-items: center;
}

.postFooterLike div:nth-child(1){
	width: 43px;
    height: 20px;
}

.postFooterLike div:nth-child(1) img{
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #FAFAFA;
	position: absolute;
	z-index: 3;
}

.postFooterLike div:nth-child(1) img:nth-child(2){
	left: 27px;
	z-index: 2;
}

.postFooterLike div:nth-child(1) img:nth-child(3){
	left: 38px;
	z-index: 1;
}

.postFooterLike div:nth-child(2){
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.postFooterDescription{
	color: #262626;
	font-size: 14px;
}

.postFooterDescriptionFacebook {
	padding: 0 15px 15px 15px;
    font-size: 12px;
    color: #292929;
}

.postFooterDescriptionFacebook mark, .postFooterDescription mark{
	background: none;
	font-style: normal;
	font-weight: 300;
	color: #8E8E8E;
}

.postFooterDate{
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	text-transform: uppercase;
	color: #8E8E8E;
}

.postMiniGallery{
	width: 301px;
	height: 521px;
	background: #FFFFFF;
	border-radius: 15px;
	overflow: hidden;
	position: absolute;
	color: #262626;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 700ms ease-in-out;
}

.postMiniGalleryFacebook{
	width: 310px;
	height: 430px;
	background: #FFFFFF;
	border-radius: 15px;
	overflow: hidden;
	position: absolute;
	color: #262626;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 700ms ease-in-out;
}

.containerMiniGallery_main{
	z-index: 2;
	filter: drop-shadow(1px 4px 4px rgba(0, 0, 0, 0.25));
}

.containerMiniGallery_next, .containerMiniGallery_last{
	filter: blur(3px);
	z-index: 1;
	transform: translate(-50%, -50%) scale(0.9);

}

.containerMiniGallery_next{
	left: 67%;
}

.containerMiniGallery_last{
	left: 33%;
}

@media all and (max-width: 600px){
	.postMiniGallery{
		height: 100%;
	}
	.postImg {
		height: 262px;
	}

	.containerMiniGallery_last{
		left: 60%;
	}

	.containerMiniGallery_next{
		left: 40%;
	}
}

.circleMiniGallery {
	width: 100%;
    height: 521px;
    position: relative;
}

.circleMiniGallery div {
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: pink;
	animation: move 15s linear infinite;
  }
  
  .circleMiniGallery div:nth-child(1) {
	animation-name: move1;
	width: 62px;
	height: 62px;
	background: #9680AC;
	filter: blur(4.5px);
	animation-delay: 5s;

	top: -3%;
    right: 8%;
    transform: translate(3%, 8%);
  }
  
  .circleMiniGallery div:nth-child(2) {
	animation-name: move2;
	width: 110px;
	height: 110px;
	background: #CC157C;
	filter: blur(4.5px);
	animation-delay: 1s;

	top: 70%;
    right: 1%;
	transform: translate(-70%, 1%);
  }
  
  .circleMiniGallery div:nth-child(3)  {
	animation-name: move3;
	width: 98px;
	height: 98px;
	background: #BC89B0;
	filter: blur(4.5px);
	animation-delay: 8s;

	bottom: 1%;
	left: 5%;
	transform: translate(-5%, 1%);
  }
  
  @keyframes move1 {
	0% {
	  transform: translate(0, 0);
	}
	30% {
	  transform: translate(-200px, 50px);
	}
	50% {
	  transform: translate(-220px, -30px);
	}
	70% {
		transform: translate(-200px, 50px);
	  }
	100% {
		transform: translate(0, 0);
	}
  }
    
  @keyframes move2 {
	0% {
	  transform: translate(0, 0);
	}
	30% {
	  transform: translate(-200px, 50px);
	}
	50% {
	  transform: translate(-220px, 90px);
	}
	70% {
		transform: translate(-200px, 50px);
	  }
	100% {
		transform: translate(0, 0);
	}
  }
  @keyframes move3 {
	0% {
		transform: translate(0, 0);
	  }
	  30% {
		transform: translate(50px, -50px);
	  }
	  50% {
		transform: translate(80px, 10px);
	  }
	  70% {
		  transform: translate(50px, -50px);
		}
	  100% {
		  transform: translate(0, 0);
	  }
  }


  @media all and (max-width: 980px) { 
	.colorSectionTextBlock, .containerMiniGallery{
		width: 100%;
	}

	.colorSectionTextBlock p {
		text-align: justify;
	}

  }	
  
  .skillsBlock{
	overflow: hidden;
  }

  .neonHeadlin{
	font-style: normal;
	font-weight: 300;
	text-transform: uppercase;
	color: #61EFFF;
	text-shadow: 0 0 15px #61efff4d;
	animation: neon 8s ease-in-out infinite;
	margin: 40px 0;
  }

  .neonHeadlin span:nth-of-type(3n) {
	animation: neon 4s ease-in-out infinite;
	animation-delay: 1s;
  }


  @keyframes neon {
	0% {
	  opacity: 0;
	  text-shadow: none;
	}
	5% {
	  opacity: 1;
	  text-shadow: 0 0 15px #61efff4d;
	}
	10% {
	  opacity: 0.2;
	  text-shadow: none;
	}	
	15% {
		opacity: 1;
		text-shadow: 0 0 15px #61efff4d;
	}
	100% {
		opacity: 1;
		text-shadow: 0 0 15px #61efff4d;
	}
  }

.copycatGitHub{
	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 575px;
	width: 100%;
	background: #161B22;
	border: 1px solid #30363D;
	border-radius: 8px;
	overflow: hidden;
}

.GitHub_header{
	position: relative;
	width: 100%;
	height: 78px;
	border-bottom: 1px solid #30363D;
	display: flex;
	align-items: center;
}

.GitHub_header > div{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.GitHub_header > div:nth-child(1){
	width: 88px;
}

.GitHub_header > div:nth-child(2){
	width: calc(100% - 88px);
}

.GitHub_headerLinkBlock{
	position: relative;
	width: 580px;
	height: 38px;
	background: #0D1117;
	border: 1px solid #30363D;
	border-radius: 8px;
	display: flex;
	justify-content:space-around;
	align-items: center;
}

.GitHub_headerLinkBlock a{
	color: #fff;
    width: 70%;
    text-align: center;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.GitHub_body{
	height: calc(100% - 78px);
	position: relative;
	width: 100%;
	display: flex;
	
}

.GitHub_body > div{
	height: 100%;
}

.GitHub_body > div:nth-child(1){
	width: 88px;
	display: flex;
	justify-content: center;
	padding: 20px;
}

.GitHub_body > div:nth-child(1), .GitHub_body > div:nth-child(2){
	border-right: 1px solid #30363D;
}
.GitHub_body > div:nth-child(2), .GitHub_body > div:nth-child(3){
	width: calc((100% - 88px) / 2);
}

@media all and (max-width: 700px) {
	.copycatGitHub {
		height: 475px;
	}
	.GitHub_header > div:nth-child(1) {
		display: none;
	}
	.GitHub_header{
		justify-content: center;
	}
	.GitHub_header > div:nth-child(2) {
		width: 90%;
	}
	.GitHub_headerLinkBlock a {
		font-size: 11px;
	}
	.GitHub_body > div:nth-child(1), .GitHub_body > div:nth-child(2){
		display: none;
	}
	.GitHub_body > div:nth-child(3){
		width: 100%;
	}
  }

.GitHub_body_headerLeft, .GitHub_body_headerRight{
	width: 100%;
	height: 65px;
	position: relative;
}

.GitHub_body_headerRight{
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.GitHub_body_headerRight svg:nth-child(2){
	width: 70%;
    text-align: center;
}

.GitHub_fileName{
	padding: 13px 26px;
	background: #0D1117;
	border-radius: 4px 4px 0px 0px;
	position: absolute;
    font-size: 14px;
    color: #7ee787;
    left: 25px;
    bottom: 0;
}

.GitHub_body_code, .GitHub_body_view{
	position: relative;
	height: calc(100% - 65px);
	width: 100%;
}

.GitHub_body_code {
	background: #0D1117;
	padding: 25px;
	overflow: hidden;
}

.GitHub_body_code > div{
	padding-bottom: 25px;
	color: #7ee787;
	counter-reset: line-numbering;
	overflow-x: auto;
}

.GitHub_body_code pre::before {
	counter-increment: line-numbering;
	content: counter(line-numbering);
	display: inline-block;
	width: 25px;
	margin-right: 0.5em;
	text-align: right;
	color: #6e7681;
}

.GitHub_body_code pre{
	margin: 5px 0;
}

.GitHub_body_code > div::-webkit-scrollbar {
	height: 15px !important;
}

.GitHub_body_code > div::-webkit-scrollbar-track {
	background-color: #424242 !important;
}

.GitHub_body_code > div::-webkit-scrollbar-thumb {
	background-color: #686868 !important;
	border-radius: 10px !important;
	border: 3px solid #424242 !important;
}

.GitHub_body_code > div::-webkit-scrollbar-thumb:hover {
	background-color: #e6e6e6 !important;
	border-radius: 10px !important;
	border: 3px solid #424242 !important;
}

.GitHub_body_view{
	background: #fff;
}

.skillsText{
	max-width: 681px;
	width: 100%;
	font-style: normal;
    font-weight: 400;
	color: #B2B2B2;
	margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 980px){
	.skillsText {
		text-align: justify;
	}
}

.mark-1, .mark-2, .mark-3{
	background: none;
	display: contents;
}

.mark-1{
	color: #93BEE3;
}

.mark-2{
	color: #65C973;
}

.mark-3{
	color: #C69FF1;
}

.skillsIcon{
	max-width: 475px;
	width: 100%;
}

.skillsIcon svg{
	width: 100%;
}

#SampleProjects{
	position: relative;
	overflow: hidden;
	width: 150%;
	height: auto;
    display: flex;
	flex-wrap: wrap;
	left: -25%;
}

.SampleProjects_line1, .SampleProjects_line2{
	min-width: min-content;
	display: flex;
	height: 340px;
}

.sampleProject_w{
	height: 284px;
}

.sampleProject_h{
	height: 100%;
}

.sampleProject_w, .sampleProject_h{
	border-radius: 16px;
}

.experienceText{
	width: 100%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 40px;
}

.experienceText > div{
	width: calc(50% - (40px / 2 ));
}

.experienceText > div div:nth-child(1){
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
}

.experienceText > div div:nth-child(2){
	font-style: normal;
	font-weight: 400;
	color: #B2B2B2;
	text-align: justify;
}

.experienceText > div div:nth-child(2) a{
	color: #fff;
	text-decoration: revert;
}

@media (max-width: 600px){
	.SampleProjects_line1, .SampleProjects_line2 {
		height: 150px;
		grid-gap: 20px;
	}
	.sampleProject_w {
		height: 120px;
	}
	#SampleProjects{
		grid-gap: 20px;
	}
	.experienceText > div {
		width: 100%;
	}
	.sampleProject_w, .sampleProject_h {
		border-radius: 8px;
	}
}

footer{
	width: 100%;
	height: auto;
	padding-top: 40px;
	position: relative;
	overflow: hidden;
}

.footerBack{
	position: absolute;
	left: 0;
	bottom: 0;
}

.footerContacts{
	width: 100%;
	border-top: 1px solid #B2B2B2;
	border-bottom: 1px solid #B2B2B2;
	min-height: 150px;
	display: flex;
    justify-content: space-between;
    align-items: center;
	position: relative;
	z-index: 1;
}

.footerSocialNetwork{
	display: flex;
	grid-gap: 20px;
}

.footerSocialNetwork a:hover{
	transform: scale(1.1);
	opacity: 0.8;
}

.footerContacts > a {
	font-style: normal;
	font-weight: 400;
	color: #FFFFFF;
}

.footerBottom{
	width: 100%;
	min-height: 190px;
	display: flex;
	justify-content: space-between;
    align-items: center;
	position: relative;
	z-index: 1;
}

.copyright{
	font-style: normal;
	font-weight: 300;
	color: #FFFFFF;
}

.footerQuote{
	max-width: 365px;
	margin-top: 21px;
}

blockquote{
	margin: 0;
}

.footerQuote blockquote p{
	font-style: normal;
	font-weight: 300;
	color: #FFFFFF;
	position: relative;
}

.footerQuote blockquote p mark{
	background: none;
	color: #fff;
	position: relative;
	text-decoration: line-through;
}

.footerQuote blockquote p mark[rightword]::after {
    content: attr(rightword);
    color: #fff;
    position: absolute;
    top: -21px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);
}

.footerQuote blockquote p mark[rightword]:nth-child(1)::after {
	color: #65C973;
}
.footerQuote blockquote p mark[rightword]:nth-child(2)::after {
	color: #C69FF1;
}

.footerQuote figcaption{
	text-align: right;
	font-style: normal;
	font-weight: 500;
}

@media (max-width: 600px){
	
	.footerContacts{
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		min-height: auto;
	}
	.footerBottom{
		min-height: auto;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.footerQuote{
		display: none;
	}
	.footerBottom svg{
		width: 100%;
	}
	.copyright {
		font-size: 14px;
		color: #b2b2b2;
	}

	.colorSectionTextBlock h2 {
		margin-bottom: 20px
	}

	.skillsText {
		margin: 20px 0;
	}	

	.experienceText > div div:nth-child(1){
		font-size: 32px;
	}

	.neonHeadlin {
		margin: 20px 0;
	}

	.circleMiniGallery {
		height: 475px;
	}
}

.IntroduceSpeechBlock{
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: #00000068;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999999999;
	top: 0;
	left: 0;
}

.IntroduceSpeechScreen{
	height: 555px;
	width: 334px;
	overflow: hidden;
	position: relative;
	border-radius: 10px;
	z-index: 9999999999999999999;
}

.IntroduceSpeechBlock video{
	position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
}

.videoPause{
	height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(circle at 50% 50%, #0b1f4f87, #1f305c1f, #0d1f50c2);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
	cursor: pointer;
}

.IntroduceSpeechScreenHeader{
	position: absolute;
    z-index: 1;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
}

.IntroduceSpeechScreenHeader progress{
    width: 100%;
	appearance: none;
    height: 4px;
	position: relative;
	border-radius: 50%;
}

.IntroduceSpeechScreenHeader progress::-webkit-progress-value {
	background: #FFFFFF;
	border-radius: 5px;
}

progress[value]::-webkit-progress-bar {
	background-color: rgba(255, 255, 255, 0.334);
	border-radius: 5px;
}

.IntroducePersonData{
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.IntroducePersonData img{
	width: 34px;
	height: 34px;
	border-radius: 50%;
}

.IntroducePersonData div{
	font-size: 14px;
	width: calc(100% - 34px - 10px - 40px);
	font-weight: 600;
}

.IntroducePersonData img{
	width: 34px;
	height: 34px;
	border-radius: 50%;
}

.IntroducePersonData svg{
	padding: 8px 11px;
    cursor: pointer;
	font-size: 20px;
}

.IntroducePersonData svg:hover{
	background: #ffffff6b;
    border-radius: 11px; 
}

.IntroduceSpeechScreenFooter{
	position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.IntroduceSpeechScreenFooter svg{
	width: 100%;
    height: auto;
}

@media (max-width: 600px){
	.IntroduceSpeechScreen{
		width: 100%;
		height: 100%;
		border-radius: 0;
	}
}

.adVideoBlock{
	position: fixed;
	right: calc(75px/2);
	bottom: 24px;
	width: 130px;
	height: 130px;
	border-radius: 50%;
	background-image: url(img/flagUA.png);
    background-position: center;
    background-size: inherit;
	padding: 7px;
	cursor: pointer;
}

.adVideo{
	position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
	z-index: 999999;
}

.adVideo video{
	width: 100%;
}
.adVideo img{
	width: 100%;
}

.adVideoBlockIcon{
	background: #fff;
	width: 45px;
	height: 45px;
	position: absolute;
	right: 5px;
	bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	z-index: 999999;
}

.adVideoBlockIcon svg {
	width: 23px;
	height: 23px;
}

@media (max-width: 600px){
	.adVideoBlock{
		right: calc(20px/2);
		bottom: 20px;
		width: 100px;
		height: 100px;
	}
	.adVideoBlockIcon {
		width: 30px;
		height: 30px;
	}
	.adVideoBlockIcon svg {
		width: 15px;
		height: 15px;
	}
}

.mainBlock{
	padding: 110px 75px 0 75px;
	position: relative;
}

.projectsListBlock{
	display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 75px;
    
}

@media (max-width: 600px){
	.mainBlock{
		padding: 110px 20px 0 20px;
		position: relative;
	}
	.projectsListBlock{
		grid-gap: 20px;
		justify-content: center;
	}
}

.mainBlock h1{
	margin-bottom: 40px;
}

.projectBlock{
	position: relative;
	background: #1C2335;
	border-radius: 15px;
	padding: 20px;
	min-height: 498px;
	width: 336px;
	display: flex;
    justify-content: end;
    align-items: start;
	grid-gap: 15px;
	color: #FFFFFF;
	flex-wrap: wrap;
}

.projectBlockImg{
	width: 100%;
    height: 210px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 11px;
}

.projectBloc img{
	width: 100%;
	height: 100%;
}

.newProjectStatus::after{
	content: 'New';
    position: absolute;
    width: 106.38px;
    height: 20px;
    background: #F2554C;
    border-radius: 5px;
    transform: rotate(51deg);
    right: -30px;
    top: 22px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.projectBlock h2{
	width: 100%;
	font-weight: 600;
}

.projectBlock h2 svg{
	width: 20px;
    height: 20px;
}

.projectTechnologies{
	width:100%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 10px;
}

.projectTechnologies div{
	border: 1px solid #FFFFFF;
	border-radius: 9px;
	padding: 10px;
	font-size: 12px;
	background: #ffffff4f;

}

.React-technology{
	background: rgba(0, 120, 237, 0.26) !important;
	border: 1px solid #0078ED !important;
	color:#BADDFF !important;
}

.JS-technology{
	background: rgba(247, 224, 24, 0.26) !important;
	border: 1px solid #F7E018!important;
	color:#FFF284 !important;
}

.HTML-technology{
	background: rgba(229, 76, 33, 0.26) !important;
	border: 1px solid #E54C21!important;
	color:#FFC8B9 !important;
}

.SCSS-technology{
	background: rgba(199, 100, 148, 0.26) !important;
	border: 1px solid #C76494!important;
	color:#FFB5D9 !important;
}

.CSS-technology{
	background: rgba(199, 100, 148, 0.26) !important;
	border: 1px solid #C76494!important;
	color:#FFB5D9 !important;
}

.Django-technology{
	background: rgb(4 169 15 / 26%) !important;
    border: 1px solid #059b2c!important;
    color: #5fd398 !important;
}

.PHP-technology{
	background: rgb(93 89 161 / 35%) !important;
    border: 1px solid #7b31bf!important;
    color: #aa68e7 !important;
}

.SQL-technology{
	background: rgb(255 240 64 / 35%) !important;
    border: 1px solid #b3e737!important;
    color: #f7f251 !important;
}

.jQuery-technology{
	background: rgba(0, 120, 237, 0.26) !important;
	border: 1px solid #0078ED !important;
	color:#BADDFF !important;
}

.GraphQL-technology{
	background: rgba(222, 51, 166, 0.26) !important;
	border: 1px solid #DE33A6!important;
	color:#FFBAE8 !important;
}

.UXUI-technology{
	background: rgba(0, 237, 95, 0.26) !important;
	border: 1px solid #00ED5F!important;
	color:#90FFBD !important;
}

.Bootstrap-technology{
	background: rgba(123, 17, 243, 0.26) !important;
	border: 1px solid #7B11F3!important;
	color:#CC9FFF !important;
}

.projectIntroduceShort{
	text-align: justify;
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.projectBlock button{
	background: #605DD1;
	color:#FFFFFF;
	border: 1px solid #605DD1!important;
	padding: 16px;
	border-radius: 8px;
	font-size: 16px;
	min-width: 102px;
	cursor: pointer;
}

.projectBlock button:hover{
	opacity: 0.8;
}

.introduceProjectBlock{
	/*padding: 0;*/
    height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.introduceProjectBlock article{
	width: 90%;
	text-align: center;
	z-index: 2;
}

.introduceProjectBlock h1{
	font-family: 'REZ';
	font-style: normal;
	font-weight: 400;
	font-size: 128px;
	margin-bottom: 30px;
}

.introduceProjectBlock p{
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
}

.introduceProjectBackground, .introduceBackgroundIcon{
	position: absolute;
    width: calc(100% - 75px - 75px);
    height: 100%;
    z-index: 1;
    overflow: hidden;
	filter: blur(42.5px);
}

.introduceProjectBackground img, .blackout{
	position: absolute;
	width:100%;
	height: 100%;
	top: 0;
}

.blackout{
	background: rgba(14, 18, 27, 0.65);
	z-index: 2;
}

.introduceBackgroundIcon{
	filter: blur(6.5px);
}

.introduceBackgroundIcon svg:nth-child(1){
	position: absolute;
	left: 12%;
	top: 20%;
	transform: rotate(-27.64deg);
	width: 130px;
	height: 130px;
}

.introduceBackgroundIcon svg:nth-child(2){
	position: absolute;
	left: 5%;
    top: 60%;
	transform: rotate(-32.73deg);
	width: 45px;
	height: 45px;
}

.introduceBackgroundIcon svg:nth-child(3){
	position: absolute;
	left: 20%;
    top: 80%;
	transform: rotate(28.72deg);
	width: 44px;
	height: 44px;
}

.introduceBackgroundIcon svg:nth-child(4){
	position: absolute;
	right: 8%;
    top: 22%;
	transform: rotate(23.33deg);
	width: 65px;
	height: 65px;
}

.introduceBackgroundIcon svg:nth-child(5){
	position: absolute;
	right: 10%;
    top: 66%;
	transform: rotate(-5.03deg);
	width: 158px;
	height: 158px;
}

@media (max-width: 600px){
	.introduceProjectBackground, .introduceBackgroundIcon{
		position: absolute;
		width: calc(100% - 20px - 20px);
		height: 100%;
		z-index: 1;
		overflow: hidden;
	}
	.introduceProjectBlock h1{
		font-size: 58px;
	}
	
	.introduceProjectBlock p{
		font-size: 16px;
	}

	.introduceBackgroundIcon svg:nth-child(1) {
		width: 80px;
		height: 80px;
		right: 19%;
		left: auto;
	}

	.introduceBackgroundIcon svg:nth-child(5), .introduceBackgroundIcon svg:nth-child(4) {
		display: none;
	}

	.introduceBackgroundIcon svg:nth-child(3){
		left: 54%;
	}

	.introduceBackgroundIcon svg:nth-child(2){
		top: 62%;
	}
}

.projectContentBlock{
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 75px;
}

.projectContentTextBlock{
	display: flex;
	width: 568px;
}

.projectCategory{
	width: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.8);
	margin-bottom: 75px;
}

.projectContentIcon{
	width: 87px;
}

.projectContentIcon svg{
	font-size: 47px;
}

.projectContentText{
	width: calc(100% - 87px);
    display: flex;
    flex-wrap: wrap;
    grid-gap: 45px;
}

.projectContentText h2{
	font-style: italic;
	font-weight: 600;
	font-size: 40px;
	text-transform: uppercase;
	color: #FFFFFF;
}

.projectReadingTime{
	display: flex;
    justify-content: space-between;
    width: 100%;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	color: rgba(255, 255, 255, 0.8);
}

.projectContentText p{
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	color: #FFFFFF;
	width: 100%;
}

.projectImgContent{
	width: calc( 100% - 568px);
	height: 100%;
	display: flex;
    justify-content: center;
}

.ImgProject1{
	max-width: 422px;
	width: 100%;
	max-height: 583px;
	height: 100%;
}

.projectButtonLink{
	width: 100%;
	padding: 1px;
	background: linear-gradient(266.71deg, #61EFFF 0%, #E0524B 100%);
	border-radius: 22px;
	overflow: hidden;
}

.projectButtonLink div{
	width: 100%;
	background: #0E1219;
	padding: 24px 20px;
	border-radius: 22px;
	color: #fff;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectButtonLink div svg:nth-child(1){
	color:#E25049;
	font-size: 47px;
}
.projectButtonLink div svg:nth-child(2){
	font-size: 47px;
}

.structureProjecImgs{
	display: flex;
	flex-wrap: wrap;
	grid-gap: 75px;
}

.structureProjecImgs img{
	width: 100%;
	border-radius: 22px;
}

.colorsBlock{
	display: flex;
	grid-gap: 20px;
	overflow-x: auto;
	position: relative;
}
@media (max-width: 600px){
	.colorProjecContent::after{
		content: '';
		width: 10%;
		height: 100%;
		position: absolute;
		max-height: 164px;
		right: -1px;
		background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(17,21,32,0.6222864145658263) 41%, rgba(17,21,32,1) 100%);
	}
}

.colorBlock{
	width: 126px;
	height: 163px;
	background: #fff;
	border-radius: 4px;
	padding: 4px;
	position: relative;
	flex-shrink: 0;
}

.colorBlock div:nth-child(1){
	width: 100%;
	height: 109px;
	border-radius: 2px;
	margin-bottom: 10px;
}

.colorBlock div:nth-child(2){
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #000000;
}

.colorBlock div:nth-child(3){
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	color: #000000;
}

.colorProjecContent {
	max-width: 710px;
	width: 100%;
	position: relative;
	grid-gap: 45px;
	display: flex;
	flex-wrap: wrap;
	margin-left: auto;
    margin-right: 0;
}

.colorProjecContent p{
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	color: #CFD0D2;
	width: 50%;
	z-index: 2;
}

.colorProjecContent p a{
	color: #CFD0D2;
	text-decoration: revert;
}

.colorProjecBlock{
	overflow:hidden;
	min-height: 1000px;
}

.colorProjecHeader{
	margin-bottom: 45px;
}

.colorProjecHeader h2{
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	color: #FFFFFF;
}

.colorProjecHeader img{
	max-width: 500px;
	width: 100%;
}

.palette1{
	position: absolute;
    top: 0;
    right: -240px;
}

.palette2{
	position: absolute;
	bottom: -86px;
    left: -295px;
}

.tassel{
	position: absolute;
    bottom: -43px;
    right: -125px;
}

.axis{
	padding: 45px;
	background: rgba(217, 217, 217, 0.01);
	border: 1px solid #494949;
	border-radius: 50%;
	position: relative;
}

.planet{
	display: inline-flex; /* задаем отображение в виде inline-flex */
  align-items: center; /* выравнивание по вертикали */
  justify-content: center; /* выравнивание по горизонтали */
  border-radius: 50%; 
  min-width: 50px; /* задаем минимальную ширину */
  min-height: 50px;
  box-sizing: border-box;
	
	position: absolute;
	padding: 10px;
	border-radius: 50%;



	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	text-transform: uppercase;


	
	animation: move 10s linear infinite;

	top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.planetCenter{
	position: relative;
	height: 87px;
	width: 87px;
	animation: none;
	transform: none;
    left: auto;
    top: auto;

}

#solarSystem{
	transform: scale(0.9);
	margin-top: 67px;
}

@keyframes move {
	from {
		
		transform: translate(-50%, -50%) rotate(0deg) translateX(-92px) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg) translateX(-92px) rotate(-360deg);
	}
}

@keyframes solarSystem {
		0%{
			border: 1px solid #494949;
		}

		10%{
			border: 1px solid #ffffff;
		}
		20%{
			border: 1px solid #494949;
		}
		
		100%{
			border: 1px solid #494949;
		}
	}

@media (max-width: 600px){
	.GitHub_body_headerRight{
		padding: 0 5%;
	}
	.projectContentIcon{
		display: none;
	}
	.projectContentText, .projectContentTextBlock{
		width: 100%;
	}
	.projectContentBlock > div{
		width: 100%;
	}
	.projectContentBlock{
		flex-wrap: wrap;
	}
	.structureProjecImgs {
		grid-gap: 20px;
	}
	.projectButtonLink div{
		font-size: 16px;
	}
	.projectButtonLink div svg:nth-child(1) {
		color: #E25049;
		font-size: 28px;
	}
	.projectContentText h2 {
		font-size: 32px;
	}
	.projectContentText p {
		font-size: 16px;
		text-align: justify;
	}
	.projectReadingTime {
		font-size: 14px;
	}
	.projectContentText {
		grid-gap: 40px;
	}
	.ImgProject1{
		display: none;
	}
	.projectContentBlock {
		grid-gap: 20px;
		overflow-x: clip;
	}
	.projectCategory {
		font-size: 14px;
		margin-bottom: 40px;
	}
	#solarSystem {
		transform: none;
		margin-top: auto;
		margin-bottom: 80px;
	}
	.colorProjecContent p {
		width: 100%;
		text-align: justify;
	}
	.colorProjecBlock {
		min-height: 900px;
	}
	.tassel {
		bottom: 0;
		right: -75px;
		width: 50%;
	}
	.palette2 {
		bottom: 109px;
		left: -105px;
		width: 75%;
	}
	.palette1{
		display: none;
	}
	.colorProjecHeader h2 {
		font-size: 32px;
	}
	.colorProjecHeader img {
		max-width: 300px;
		width: 100%;
	}
	.colorProjecContent {
		grid-gap: 20px;
	}
}

.defaultInput{
	width: 100%;
	height: 48px;
	padding: 0 15px;
	background: rgba(217, 217, 217, 0.06);
	border: 1px solid #D9D9D9;
	border-radius: 8px;
	position: relative;
	margin-top: 25px;
	display: flex;
    align-items: center;
	
}

.defaultInput:focus-within::before{
	content: '';
    width: calc(100% - 30px - 2px);
    height: 1px;
    position: absolute;
    background: #D9D9D9;
    bottom: 5px;
}

.defaultInput input{
	width: 100%;
	height: 100%;
	border: none;
	font-size: 16px;
	padding: 0;
	color: #FFFFFF;
	background: none;
}
.defaultInput input:focus-visible,.proNumberInput input:focus-visible {
	border: none;
    box-shadow: none;
	padding: 0;
	outline: none;
}

.defaultInput textarea{
	width: 100%;
	height: 100%;
	border: none;
	font-size: 16px;
	color: #FFFFFF;
	background: none;
	font-family: 'Inter';
	padding-top: 10px;
	max-height: 99%;
    min-height: 99%;
	max-width: 99%;
    min-width: 99%;
}
.defaultInput textarea:focus-visible,.proNumberInput textarea:focus-visible {
	border: none;
    box-shadow: none;
	padding-top: 10px;
	outline: none;
}

.defaultInput label{
	position: absolute;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    top: -25px;
	left: 0;
}

.defaultInput > svg{
	position: absolute;
    bottom: 9px;
	right: 18px;
    font-size: 24px;
}

.green{
	color: #6E8935;
}

.red{
	color: #F2554C;
}

.requirementsInputBlock{
	padding: 15px;
	width: 100%;
	height: auto;
	border-radius: 10px;
	background: rgb(28 32 42);
}

.requirementsInputBlock > div{
	text-align: left;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	color: #ffffff;
}

.requirementsInputBlock ul{
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #D9D9D9;
	margin: 0;
	display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    margin-top: 10px;
    padding-left: 20px;
}
.requirementsInputBlock ul li{
	width: 100%;
}

.contactBlock h2{
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	color: #FFFFFF;
}

.contactForm{
	width: 100%;
	max-width: 375px;
	display: flex;
	grid-gap: 20px;
	flex-wrap: wrap;
}

.defaultButtonFull{
	width: 100%;
	height: auto;
	color: #fff;
	font-size: 16px;
	padding: 16px;
	cursor: pointer;
	background: #F2554C;
	border: 1px solid #F2554C;
	border-radius: 8px;
}

.defaultButtonFull:hover{
	opacity: 0.9;
}

.defaultButtonFull:active{
	-webkit-transform: scale(0.94);
    transform: scale(0.94);
}

.PhoneInput{
	display: flex;
	align-items: center;
}
.PhoneInputCountry{
	margin-right: 10px;
	display: flex;
}
.PhoneInputCountrySelect{
	display: none;
}

.PhoneInputCountryIcon{
	width: 30px;
	display: flex;
}

.contactBlock{
	display: flex;
    justify-content: space-between;
}

.contactBlockAvatar{
	width: calc(100% - 375px - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px;
    position: sticky;
    top: 80px;
}

.contactBlockAvatar::after{
	content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    background: #F2554C;
    filter: blur(65px);

    border-radius: 50%;
	animation: 10s linear 1s infinite normal none running rotationLeft;
}

.contactBlockAvatar::before{
	content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    background: #61EFFF;
    filter: blur(65px);

    border-radius: 50%;
	
	animation: 10s linear 1s infinite normal none running rotationRight;
}
.contactBlockAvatar img{
	
    height: 180px;
	z-index: 1;
}

@keyframes rotationLeft {
	0%{
		transform:  rotate(0deg) translateX(-50px) rotate(0deg);
	}

	100% {
		transform:  rotate(360deg) translateX(-50px) rotate(-360deg);
	}
}

@keyframes rotationRight {
	0%{
		transform:  rotate(0deg) translateX(50px) rotate(0deg);
	}

	100% {
		transform:  rotate(360deg) translateX(50px) rotate(-360deg);
	}
}

@media (max-width: 600px){
	.contactBlockAvatar{
		display: none;
	}
}

.alert{
	position: fixed;
    top: 24px;
    right: 75px;
    padding: 20px;
    border-radius: 15px;
    z-index: 999999999999999;
	opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.alert-success{
    background: rgb(35 61 46);
    border: 1px solid #55a764;
}

.alert-danger{
	background: rgb(139 55 52);
    border: 1px solid #f2554c;
}

@media (max-width: 600px){
	.alert{
		top: 20px;
    	right: 20px;
		max-width: 90%;
		font-size: 14px;
	}
}

.notFound404{
	width: 100%;
    height: 100%;
    position: fixed;
    background: #181743;
    background-image: url('img/NotFound404Img.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.notFound404 a{
	position: absolute;
    bottom: 20px;
    color: #fff;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    text-decoration: revert;
}

.GitHub_body_view{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
}

video::-webkit-media-controls {
    display:none !important;
}